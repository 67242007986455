import * as React from "react";
import { useEffect, useId, useState } from "react";
import { faHeart } from "@fortawesome/free-regular-svg-icons";
import { faChevronRight, faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import IconText from "~/components/IconText";
import {
  FloatingPortal,
  flip,
  offset,
  safePolygon,
  useDismiss,
  useFloating,
  useHover,
  useInteractions
} from "@floating-ui/react";
import type { PossibleScotContent, ScotContent } from "~/utils/scot";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { vote } from "~/utils/transactions";
import type { HiveContent, AuthorPerm } from "~/utils/hive";

import classNames from "classnames";
import produce from "immer";
import { isSSR } from "~/utils/ssr";
import { toast } from "react-toastify";
import { useLocation } from "@remix-run/react";
import { FloatingHTMLOverlay } from "../FloatingHTMLOverlay";
import { estimateMyVoteValue, getTribeAccountInfo, getTribeConfig } from "~/utils/hive";
import { DefaultCurrencyOutput } from "../Payout";
import { cache } from "~/utils/cache";
import { SmallAvatar6, SmallAvatar8 } from "./SmallAvatar";
import { useAppStore } from "~/store";
import Popover from "../Popover";
import { cn } from "~/utils/cn";
import { AnimatePresence, motion } from "framer-motion";
import { useMediaQuery } from "~/hooks/useMediaQuery";

const VotersView = React.lazy(() => import("./VotersView"));
const ProfileLink = React.lazy(() => import("~/components/ProfileLink"));

export function useLikeToggleState() {
  return useState(false);
}

export function useLiked(accountName: string | null, scotContent: PossibleScotContent) {
  const [liked, setLiked] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      if (liked === true) return setLiked(true);
      (() => {
        if (accountName === null) {
          return;
        }
        if (scotContent === null) {
          return;
        }
        const liked = scotContent?.active_votes?.find(({ voter }) => voter === accountName);
        setLiked(liked !== undefined);
      })();
    }, 250);
  }, [accountName, scotContent, setLiked]);

  return liked;
}

interface LikeCountProps {
  likeCount: number;
  scotContent: PossibleScotContent;
  discussion: HiveContent;
  liked?: boolean;
  setLikeToggle?: React.Dispatch<React.SetStateAction<boolean>>;
  className?: string;
  paidOut?: boolean;
}

export default function LikeCount({
  likeCount,
  scotContent,
  discussion,
  liked,
  setLikeToggle,
  className,
  paidOut
}: LikeCountProps) {
  const id = useId();
  const [open, setOpen] = useState(false);
  const [votesView, setVotesView] = useState(false);

  const { x, y, reference, floating, strategy, context } = useFloating({
    open,
    onOpenChange: open => scotContent !== null && (scotContent?.active_votes?.length !== 0 || 0) && setOpen(open),
    middleware: [offset(7), flip()]
  });

  const dismiss = useDismiss(context, {
    ancestorScroll: true
  });

  const { getReferenceProps, getFloatingProps } = useInteractions([
    useHover(context, {
      mouseOnly: true,
      handleClose: safePolygon(),
      delay: 250
    }),
    dismiss
  ]);

  return (
    <React.Fragment>
      <div
        onClick={ev => {
          ev?.stopPropagation();
          ev?.preventDefault();
          if (setLikeToggle !== undefined) {
            if (paidOut === true) return;
            setLikeToggle(state => !state);
          }
        }}
        id={`like-toggle-${id}`}
        aria-label="Like"
        role="button"
        title="Like"
        className={classNames("relative like-button flex items-center gap-x-0.5", {
          liked: liked
        })}
        {...getReferenceProps}
      >
        <div className="like-wrapper group/likecount relative w-8 h-8 flex items-center rounded-full text-gray-500 dark:text-zinc-500 transition-colors cursor-pointer">
          <span className="ripple" />

          <div
            className={classNames(
              "flex justify-center items-center rounded-full w-8 h-8 group-hover/likecount:bg-[#ea442b]/[.15] group-hover/likecount:text-[#ea442b] transition-all duration-150",
              {
                "bg-[#ea442b]/[.15] text-acc": open
              },
              className
            )}
          >
            <FontAwesomeIcon
              className={classNames("heart w-4 h-4 sm:w-5 sm:h-5", {
                // "text-acc dark:text-acc-d text-lg": liked
              })}
              icon={liked ? faHeartSolid : faHeart}
              fixedWidth
            />
          </div>

          <div className="particles [--total-particles:_6]">
            <div className="particle [--i:_1] [--color:_#7642F0]" />
            <div className="particle [--i:_2] [--color:_#AFD27F]" />
            <div className="particle [--i:_3] [--color:_#DE8F4F]" />
            <div className="particle [--i:_4] [--color:_#D0516B]" />
            <div className="particle [--i:_5] [--color:_#5686F2]" />
            <div className="particle [--i:_6] [--color:_#D53EF3]" />
          </div>
        </div>
        <span
          ref={reference}
          className={cn("pl-0.5 transition-all duration-150", {
            "text-gray-500 dark:text-zinc-500 group-hover/likecount:text-[#ea442b] dark:group-hover/likecount:text-[#ea442b]":
              !liked,
            "text-[#ea442b]": (open && !paidOut) || liked,
            "opacity-25": paidOut
          })}
        >
          {likeCount}
        </span>
      </div>

      <FloatingPortal>
        {open && (
          <FloatingHTMLOverlay className="z-[100] relative overflow-hidden">
            <div
              className="hidden sm:block min-w-[200px] bg-pri dark:bg-pri-d border border-pri dark:border-pri-d rounded-lg text-sm z-1000 overflow-hidden drop-shadow-md shadow-[0_0_12px_3px_rgb(255_255_255_/_12%)]"
              ref={floating}
              style={{ position: strategy, top: y ?? 0, left: x ?? 0 }}
              {...getFloatingProps()}
            >
              <Voters scotContent={scotContent!} />
              {scotContent && scotContent.active_votes?.length > 3 ? (
                <div
                  role="button"
                  onClick={() => {
                    setOpen(false);
                    setVotesView(true);
                  }}
                  className="flex items-center gap-x-1 px-4 pt-2.5 pb-3 border-t border-pri dark:border-pri-d hover:bg-pri-d/10 dark:hover:bg-pri/10 transition-colors duration-150 cursor-pointer"
                >
                  <div className="flex items-center -space-x-2">
                    {scotContent.active_votes?.slice(3, 6).map((vote, index) => (
                      <span
                        key={index}
                        className="flex size-6 rounded-full bg-gray-200 dark:bg-zinc-800 overflow-hidden"
                      >
                        <SmallAvatar6 author={vote.voter} className="border-bg dark:border-bg-d" />
                      </span>
                    ))}
                  </div>

                  <span className="flex items-center text-gray-500 dark:text-zinc-400 text-sm">
                    {`+${scotContent.active_votes?.length - 3} more`}
                  </span>

                  <span className="flex items-center gap-x-2 pl-4">
                    See All
                    <FontAwesomeIcon icon={faChevronRight} size="sm" />
                  </span>
                </div>
              ) : null}
            </div>
          </FloatingHTMLOverlay>
        )}
      </FloatingPortal>

      <React.Suspense>
        {votesView ? (
          <VotersView
            visibility={votesView}
            setVisibility={setVotesView}
            discussion={discussion}
            scotContent={scotContent}
          />
        ) : null}
      </React.Suspense>

      {paidOut && <Popover anchorId={`like-toggle-${id}`} content="This post is paid out." />}
    </React.Fragment>
  );
}

interface VotersProps {
  scotContent: ScotContent;
}

function Voters({ scotContent }: VotersProps) {
  return (
    <div className="flex flex-col divide-y divide-solid divide-pri/50 dark:divide-pri-d/50">
      {scotContent.active_votes?.slice(0, 3).map(x => (
        <React.Suspense key={x.voter} fallback={<></>}>
          <ProfileLink
            className="flex flex-1 items-center gap-x-2 py-2.5 px-4 font-medium cursor-pointer hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] transition-colors duration-150"
            accountName={x.voter}
          >
            <SmallAvatar8 author={x.voter} />
            {x.voter}
          </ProfileLink>
        </React.Suspense>
      ))}
    </div>
  );
}

const ranges = [0, 25, 50, 75, 100];

interface LikeSliderProps {
  accountName: string;
  authorPerm: AuthorPerm;
  likeToggle: boolean;
  scotContent: PossibleScotContent;
  discussion: HiveContent;
  className?: string;
  isShorts?: boolean;
  onSuccess?: () => void;
  setLikeToggle: React.Dispatch<React.SetStateAction<boolean>>;
  setScotContent: React.Dispatch<React.SetStateAction<PossibleScotContent>>;
}

export function LikeSlider({
  accountName,
  authorPerm,
  likeToggle,
  discussion,
  scotContent,
  className,
  isShorts,
  onSuccess,
  setLikeToggle,
  setScotContent
}: LikeSliderProps) {
  const settings = useAppStore(store => store.settings);
  const isDarkMode = useAppStore(store => store.settings.dark);
  const tokenPrices = useAppStore(store => store.wallet.tokenPrices);

  const { pathname } = useLocation();
  const id = useId();
  const isMobile = useMediaQuery("(max-width: 576px)");

  let oneClickVotesEnabled = settings.thread_weight || 0;
  let oneClickShortsEnabled = settings.shorts_weight || 0;
  let oneClickPostsEnabled = settings.post_weight || 0;

  const isShortsVideo = authorPerm?.body?.includes("https://3speak.tv/watch?v=leoshorts");

  const regex = /https:\/\/3speak\.tv\/watch\?v=([^\/]+)\/([^\/]+)/;
  const match = (authorPerm?.body || "")?.match(regex);

  const [, shortsAuthor, shortsPermlink] = match?.length < 2 || match?.length === undefined ? ["", "", ""] : match;

  const author = isShortsVideo ? shortsAuthor : authorPerm?.author;
  const permlink = isShortsVideo ? shortsPermlink?.split(" ")[0].replaceAll(" ", "")?.trim() : authorPerm?.permlink;

  const isThread =
    authorPerm?.index ||
    authorPerm?.permlink?.startsWith("re-leothreads") ||
    pathname?.startsWith("/threads/") ||
    false;

  const isComment = !isThread && pathname !== `/@${authorPerm?.author}/${authorPerm?.permlink}`;

  const [value, setValue] = useState(10);

  useEffect(() => {
    if (isThread) {
      setValue(oneClickVotesEnabled > 0 ? oneClickVotesEnabled : 5);
    } else if (isShorts) {
      setValue(oneClickShortsEnabled > 0 ? oneClickShortsEnabled : 10);
    } else {
      setValue(oneClickPostsEnabled > 0 ? oneClickPostsEnabled : 10);
    }
  }, [isThread, isShorts, oneClickVotesEnabled, oneClickShortsEnabled, oneClickPostsEnabled]);

  const [engineAccount, setEngineAccount] = useState();
  const [lock, setLock] = useState(false);
  const [votesView, setVotesView] = useState(false);
  const [estimatedVote, setEstimatedVote] = useState(undefined);

  useEffect(() => {
    if (!likeToggle) return;

    if (
      (isThread && oneClickVotesEnabled > 0) ||
      (!isThread && oneClickPostsEnabled > 0 && likeToggle === true) ||
      (isShorts && oneClickShortsEnabled > 0 && likeToggle === true)
    ) {
      setLikeToggle(false);
      setTimeout(() => {
        return handleVote();
      }, 500);
    }
  }, [likeToggle]);

  useEffect(() => {
    void (async function () {
      const engineAccount = await getTribeAccountInfo(accountName);
      setEngineAccount(engineAccount);
    })();
  }, [accountName]);

  useEffect(() => {
    if (engineAccount === undefined || !likeToggle || !tokenPrices) return;

    void (async function () {
      const estimatedVote = await estimateMyVoteValue(
        [],
        engineAccount,
        value,
        getTribeConfig(),
        await cache.getTribeInfo(),
        tokenPrices
      );
      setEstimatedVote(estimatedVote);
    })();
  }, [accountName, value, engineAccount]);

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    ev?.preventDefault();
    const value = parseInt(ev.target.value);
    setValue(value);
  };

  const handleCancel = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev?.stopPropagation();
    ev?.preventDefault();

    setLikeToggle(false);
  };

  const handleVote = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev?.stopPropagation();
    ev?.preventDefault();

    setLock(true);
    const weight = value * 100;

    //console.log({ authorPerm });

    vote(accountName, { author, permlink } as AuthorPerm, weight)
      .then(() => {
        setScotContent(scotContent => {
          if (scotContent === null) {
            return null;
          }
          return produce(scotContent, draft => {
            draft.active_votes?.unshift({
              voter: accountName,
              rshares: 0,
              percent: weight
            });
          });
        });
        setLikeToggle(false);
      })
      .then(result => {
        setLock(false);
        toast(
          `Your ${value}% vote on ${
            isThread
              ? `thread of @${authorPerm.author}`
              : isComment
              ? `comment of @${authorPerm.author}`
              : `post "${authorPerm.title ?? authorPerm.permlink}"`
          } is successfully transacted.`,
          {
            type: "success",
            theme: isDarkMode ? "dark" : "light",
            autoClose: 3_000
          }
        );

        if (typeof onSuccess === "function") {
          onSuccess();
        }
      })
      .catch(async a => {
        {
          console.log({ ERROR_WHILE_LIKING: a });
          const account = accountName;
          const webhook_url =
            "https://discord.com/api/webhooks/1207372542445096960/fDoGN6WTC80Kk0mgNZS5ixILQTGrCMha2mnJbfSGMxtCG0qsLVcJbspKI2kWE-JVEFPs";

          const content =
            account +
            " encountered an error while voting a post\n" +
            "```js\nError\n- url: " +
            location.pathname +
            ",\n- data: " +
            accountName +
            " " +
            authorPerm.author +
            " " +
            authorPerm.permlink;
          "" +
            weight +
            ",\n- stack: " +
            JSON.stringify(a?.stack) +
            ",\n- message: " +
            JSON.stringify(a?.message) +
            "```";

          await fetch(webhook_url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json"
            },
            body: JSON.stringify({
              content: content
            })
          });
        }
        setLock(false);
        toast(
          `Your ${value}% vote on ${
            isThread
              ? `thread of @${authorPerm.author}`
              : isComment
              ? `comment of @${authorPerm.author}`
              : `post "${authorPerm.title ?? authorPerm.permlink}"`
          } is failed to be transacted.`,
          {
            type: "error",
            theme: isDarkMode ? "dark" : "light",
            autoClose: 3_000
          }
        );
      });
  };

  if (!likeToggle) return null;

  if (isMobile) {
    return (
      <React.Fragment>
        <FloatingPortal>
          <AnimatePresence>
            {likeToggle ? (
              <React.Fragment>
                <motion.span
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.15 }}
                  className="fixed inset-0 w-full h-full bg-black/60 z-1000"
                  onClick={() => setLikeToggle(false)}
                />

                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.15 }}
                  className="z-[10000] fixed mt-auto w-full left-0 bottom-0 flex flex-col gap-y-3"
                  onClick={ev => ev.stopPropagation()}
                >
                  {(scotContent?.active_votes || []).length > 2 || (discussion?.active_votes || []).length > 2 ? (
                    <motion.button
                      initial={{ opacity: 0, y: 6 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: 6 }}
                      transition={{ duration: 0.15, delay: 0.3 }}
                      className="flex flex-row items-center gap-x-2 py-2 px-4 mx-2 w-fit rounded-full bg-pri dark:bg-pri-d border border-pri dark:border-pri-d text-sm font-semibold shadow-[0_0_12px_3px_rgb(255_255_255_/_15%)] origin-center active:scale-95 transition-transform duration-150"
                      onClick={() => setVotesView(true)}
                    >
                      <div className="flex items-center -space-x-2">
                        {scotContent ? (
                          scotContent.active_votes?.slice(0, 3).map((x, index) => (
                            <span
                              key={index}
                              className="flex size-8 bg-gray-200 dark:bg-zinc-800 rounded-full border-2 border-bg dark:border-bg-d overflow-hidden"
                            >
                              <SmallAvatar8 author={x.voter} className="border-none outline-none" />
                            </span>
                          ))
                        ) : (
                          <React.Fragment>
                            <span className="flex size-8 bg-gray-200 dark:bg-zinc-800 rounded-full border-2 border-bg dark:border-bg-d" />
                            <span className="flex size-8 bg-gray-200 dark:bg-zinc-800 rounded-full border-2 border-bg dark:border-bg-d" />
                            <span className="flex size-8 bg-gray-200 dark:bg-zinc-800 rounded-full border-2 border-bg dark:border-bg-d" />
                          </React.Fragment>
                        )}
                      </div>

                      <span>See other {scotContent?.active_votes?.length || 0} voters</span>
                      <FontAwesomeIcon icon={faChevronRight} size="sm" fixedWidth />
                    </motion.button>
                  ) : null}

                  <motion.div
                    initial={{ opacity: 0, y: "50%" }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: "50%" }}
                    transition={{
                      duration: 0.15,
                      bounce: 0,
                      damping: 0
                    }}
                    className="flex flex-col justify-between items-center gap-6 px-5 w-full h-fit min-h-[350px] border border-pri dark:border-pri-d rounded-2xl font-semibold text-xl bg-pri dark:bg-pri-d shadow-[0_0_12px_3px_rgb(255_255_255_/_15%)] overflow-y-auto overflow-hidden"
                  >
                    <span className="flex items-center text-2xl font-semibold py-4 pb-3 self-start">
                      <span>Vote</span>
                      <span className="px-2">
                        <SmallAvatar8 author={authorPerm.author} />
                      </span>
                      <span>{authorPerm.author}</span>
                    </span>

                    <div className="flex flex-row flex-wrap w-full gap-3 pb-9">
                      <button
                        type="button"
                        className={classNames(
                          "flex flex-1 justify-center items-center py-4 px-4 min-w-[calc(50%-6px)] rounded-lg border border-pri dark:border-pri-d text-sm font-medium text-pri dark:text-pri-d transition-colors duration-150",
                          {
                            "bg-acc !border-acc !dark:border-acc": value === 25
                          }
                        )}
                        value={25}
                        onClick={handleChange}
                      >
                        25%
                      </button>
                      <button
                        type="button"
                        className={classNames(
                          "flex flex-1 justify-center items-center py-4 px-4 min-w-[calc(50%-6px)] rounded-lg border border-pri dark:border-pri-d text-sm font-medium text-pri dark:text-pri-d transition-colors duration-150",
                          {
                            "bg-acc !border-acc !dark:border-acc": value === 50
                          }
                        )}
                        value={50}
                        onClick={handleChange}
                      >
                        50%
                      </button>
                      <button
                        type="button"
                        className={classNames(
                          "flex flex-1 justify-center items-center py-4 px-4 min-w-[calc(50%-6px)] rounded-lg border border-pri dark:border-pri-d text-sm font-medium text-pri dark:text-pri-d transition-colors duration-150",
                          {
                            "bg-acc !border-acc !dark:border-acc": value === 75
                          }
                        )}
                        value={75}
                        onClick={handleChange}
                      >
                        75%
                      </button>
                      <button
                        type="button"
                        className={classNames(
                          "flex flex-1 justify-center items-center py-4 px-4 min-w-[calc(50%-6px)] rounded-lg border border-pri dark:border-pri-d text-sm font-medium text-pri dark:text-pri-d transition-colors duration-150",
                          {
                            "bg-acc !border-acc !dark:border-acc": value === 100
                          }
                        )}
                        value={100}
                        onClick={handleChange}
                      >
                        100%
                      </button>
                    </div>

                    <div className="flex flex-col w-full pb-4">
                      <div className="relative flex flex-col w-full">
                        <input
                          list={id}
                          className="absolute -top-[8px] h-2 w-full accent-acc appearance-none bg-transparent z-10"
                          type="range"
                          value={value}
                          step={1}
                          min={0}
                          max={100}
                          onChange={handleChange}
                        />
                        <div className="-top-[6px] relative h-1 w-full pointer-events-none rounded-full bg-gray-400">
                          <div className="h-1 bg-acc rounded-full" style={{ width: `calc(${value}%)` }} />
                        </div>
                      </div>
                      <div id={id} className="relative flex items-center w-full h-6 pt-2 text-center">
                        {ranges.map(num => (
                          <div
                            key={num}
                            className={classNames(
                              "flex w-[20%] select-none cursor-pointer z-0 font-sans font-semibold",
                              {
                                "ml-[2px]": num === 0,
                                "pl-[5%]": num === 25,
                                "justify-center items-center": num === 50,
                                "pl-[12%]": num === 75,
                                "justify-end items-end mr-[2px]": num === 100
                              }
                            )}
                            style={{
                              left: `${num}%`
                            }}
                            onClick={() => setValue(num)}
                          >
                            <div
                              className={classNames(
                                "absolute -top-[1rem] bg-pri-d dark:bg-pri rounded-[3px] rotate-45 h-3 w-3 shadow-lg z-0",
                                { "!bg-acc": value >= num && value !== 0 }
                              )}
                            />
                            {num}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="flex flex-1 flex-col justify-end items-center gap-y-2 w-full mt-auto pb-3">
                      <button
                        type="button"
                        className="px-4 py-4 text-base text-center font-semibold text-pri dark:text-pri-d rounded-lg bg-acc hover:bg-acc-hov flex flex-row items-center justify-center w-full gap-2"
                        onClick={handleVote}
                        disabled={lock}
                        data-prevent-routing
                      >
                        Vote {value}%
                      </button>

                      <button
                        type="button"
                        className="flex justify-center items-center w-full px-4 py-4 text-base text-center font-semibold text-pri dark:text-pri-d rounded-lg border border-pri dark:border-pri-d hover:bg-zinc-400 hover:dark:bg-zinc-600"
                        onClick={handleCancel}
                        data-prevent-routing
                      >
                        Cancel
                      </button>
                    </div>
                  </motion.div>
                </motion.div>
              </React.Fragment>
            ) : null}
          </AnimatePresence>
        </FloatingPortal>
        <React.Suspense>
          {likeToggle && votesView && (
            <VotersView
              visibility={votesView}
              setVisibility={setVotesView}
              discussion={discussion}
              scotContent={scotContent}
            />
          )}
        </React.Suspense>
      </React.Fragment>
    );
  }

  return (
    <div
      id="like-slider"
      className={cn(
        "absolute w-full min-w-[310px] lg:min-w-[340px] bg-pri dark:bg-pri-d py-2.5 px-4 left-0 border border-pri dark:border-pri-d rounded-lg drop-shadow-lg shadow-[0_0_12px_3px_rgb(255_255_255_/_12%)] z-50",
        {
          "top-[-140px]": pathname?.startsWith("/@"),
          "top-0": !pathname?.startsWith("/@")
        },
        className
      )}
      onClick={ev => {
        ev?.stopPropagation();
        ev?.preventDefault();
      }}
      data-prevent-routing
    >
      <div className="flex flex-row w-full items-start gap-x-4">
        <div className="flex flex-col w-full py-4">
          <div className="relative flex flex-col w-full">
            <input
              list={id}
              className="absolute -top-[10px] h-2 w-full accent-acc appearance-none bg-transparent z-10"
              type="range"
              value={value}
              step={1}
              min={0}
              max={100}
              onChange={event => handleChange(event)}
            />
            <div className="-top-[8px] relative h-1 w-full pointer-events-none rounded-full bg-gray-400">
              <div className="h-1 bg-acc rounded-full" style={{ width: `calc(${value}%)` }} />
            </div>
          </div>
          <div id={id} className="relative flex items-center w-full h-6 pt-2 text-center">
            {ranges.map(num => (
              <div
                key={num}
                className={classNames("flex w-[20%] select-none cursor-pointer z-0 font-sans font-semibold", {
                  "ml-[2px]": num === 0,
                  "pl-[5%]": num === 25,
                  "justify-center items-center": num === 50,
                  "pl-[12%]": num === 75,
                  "justify-end items-end mr-[2px]": num === 100
                })}
                style={{
                  left: `${num}%`
                }}
                onClick={() => setValue(num)}
              >
                <div
                  className={classNames("absolute -top-[1rem] rounded-[3px] rotate-45 h-3 w-3 shadow-lg z-0", {
                    "bg-gray-500 dark:bg-pri": value < num,
                    "!bg-acc": value >= num && value !== 0
                  })}
                />
                {num}
              </div>
            ))}
          </div>
        </div>
        <div className="flex items-center justify-center min-w-[68px] font-medium text-center text-sm py-1.5 px-2.5 rounded-lg bg-green-500/10 text-green-500 z-500 whitespace-nowrap">
          +{DefaultCurrencyOutput(+estimatedVote?.sum || 0, settings.default_currency, tokenPrices)}
        </div>
      </div>

      <div className="flex items-center gap-x-2 w-full mt-auto pb-3">
        <button
          type="button"
          className="flex justify-center items-center w-full px-4 py-3 text-sm text-center font-semibold text-pri dark:text-pri-d rounded-lg border border-pri dark:border-pri-d hover:bg-pri-hov hover:dark:bg-pri-hov-d transition-colors duration-150"
          onClick={event => handleCancel(event)}
          data-prevent-routing
        >
          Cancel
        </button>

        <button
          type="button"
          className="px-4 py-3 text-sm text-center font-semibold text-pri dark:text-pri-d rounded-lg bg-acc hover:bg-acc-hov flex flex-row items-center justify-center w-full gap-2 transition-colors duration-150"
          onClick={event => handleVote(event)}
          disabled={lock}
          data-prevent-routing
        >
          Vote {value}%
        </button>
      </div>
    </div>
  );
}

export function DownVoteSlider({
  accountName,
  authorPerm,
  likeToggle,
  type,
  className,
  onSuccess,
  setLikeToggle,
  setScotContent
}: LikeSliderProps & { type?: "thread" | "post" | "comment" }) {
  const activeAccount = useAppStore(store => store.account.activeAccount);
  const isDarkMode = useAppStore(store => store.settings.dark);

  const { pathname } = useLocation();
  const isMobile = isSSR()
    ? false
    : /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(window.navigator.userAgent);

  const isThread =
    authorPerm?.index ||
    authorPerm?.permlink?.startsWith("re-leothreads") ||
    pathname?.startsWith("/threads/view/") ||
    false;

  const isComment = !isThread && pathname !== `/@${authorPerm?.author}/${authorPerm?.permlink}`;

  const [value, setValue] = useState(() => {
    if (isThread) {
      return 5;
    } else {
      return 10;
    }
  });
  const [lock, setLock] = useState(false);

  const id = useId();

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement>) => {
    ev?.preventDefault();
    const value = parseInt(ev.target.value);
    setValue(Math.max(value, 1));
  };

  const handleCancel = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev?.stopPropagation();
    ev?.preventDefault();

    setLikeToggle(false);
  };

  const handleVote = async (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev?.stopPropagation();
    ev?.preventDefault();

    if (!activeAccount || !authorPerm) return;

    if (+activeAccount.reputation < 0) {
      return toast("You need to have reputation that more than 0.", {
        type: "error",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3_000
      });
    }

    const author = await cache.getAccount(authorPerm.author);

    if (+activeAccount.reputation < +author.reputation) {
      return toast("You need to have more reputation than author for downvote.", {
        type: "error",
        theme: isDarkMode ? "dark" : "light",
        autoClose: 3_000
      });
    }

    setLock(true);
    const weight = value * -100;

    vote(accountName, authorPerm, weight)
      .then(() => {
        setScotContent(scotContent => {
          if (scotContent === null) {
            return null;
          }
          return produce(scotContent, draft => {
            draft.active_votes?.unshift({
              voter: accountName,
              rshares: 0,
              percent: weight
            });
          });
        });
        setLikeToggle(false);
      })
      .catch(console.error)
      .finally(() => {
        setLock(false);
        toast(
          `Your ${value}% down vote on ${
            isThread
              ? `thread of @${authorPerm.author}`
              : isComment
              ? `comment of @${authorPerm.author}`
              : `post "${authorPerm.title ?? authorPerm.permlink}"`
          } is successfully transacted.`,
          {
            type: "success",
            theme: isDarkMode ? "dark" : "light",
            autoClose: 3_000
          }
        );
        if (typeof onSuccess === "function") {
          onSuccess();
        }
      });
  };

  if (!likeToggle) return null;

  return type === "post" ? (
    <FloatingPortal>
      <FloatingHTMLOverlay
        id="down-slider"
        onClick={ev => ev.stopPropagation()}
        className={classNames(`duration-50 backdrop-blur-sm bg-overlay z-[1000] flex justify-center py-[10vh]`)}
        lockScroll
      >
        <div className="relative flex flex-col w-[30rem] pc:w-3/12 pc:min-w-[340px] h-fit rounded-lg bg-gray-100 dark:bg-zinc-800 overflow-hidden">
          <div
            id="down-slider"
            className={cn(
              "w-full min-w-[310px] lg:min-w-[340px] bg-pri dark:bg-pri-d py-2.5 px-4 top-0 left-0 border border-pri dark:border-pri-d rounded-lg drop-shadow-lg z-50",
              className
            )}
            onClick={ev => {
              ev?.stopPropagation();
              ev?.preventDefault();
            }}
            data-prevent-routing
          >
            <div className="flex flex-col w-full py-4">
              <h1 className="flex text-xl font-bold pb-4 mb-4">Down Vote {authorPerm.author}'s Post</h1>
              <div className="relative flex flex-col w-full">
                <input
                  list={id}
                  className="absolute -top-[10px] h-2 w-full accent-red-500 appearance-none bg-transparent z-10"
                  type="range"
                  value={value}
                  step={1}
                  min={0}
                  max={100}
                  onChange={handleChange}
                />
                <div className="-top-[8px] relative h-1 w-full pointer-events-none rounded-full bg-gray-400">
                  <div className="h-1 bg-red-500 rounded-full" style={{ width: `calc(${value}%)` }} />
                </div>
              </div>
              <div id={id} className="relative flex items-center w-full h-6 pt-2 text-center">
                {ranges.map(num => (
                  <div
                    key={num}
                    className={classNames(
                      "flex w-[20%] select-none cursor-pointer z-0 font-sans font-semibold text-red-500",
                      {
                        "ml-[2px]": num === 0,
                        "pl-[5%]": num === 25,
                        "justify-center items-center": num === 50,
                        "pl-[12%]": num === 75,
                        "justify-end items-end mr-[2px]": num === 100
                      }
                    )}
                    style={{
                      left: `${num}%`
                    }}
                    onClick={() => setValue(num)}
                  >
                    <div
                      className={classNames("absolute -top-[1rem] rounded-[3px] rotate-45 h-3 w-3 shadow-lg z-0", {
                        "bg-gray-500 dark:bg-pri": value < num,
                        "!bg-red-500": value >= num && value !== 0
                      })}
                    />
                    -{num}
                  </div>
                ))}
              </div>
            </div>

            <div className="flex items-center gap-x-2 w-full mt-auto pb-3">
              <button
                type="button"
                className="flex justify-center items-center w-full px-4 py-3 text-sm text-center font-semibold text-pri dark:text-pri-d rounded-lg border border-pri dark:border-pri-d hover:bg-pri-hov hover:dark:bg-pri-hov-d transition-colors duration-150"
                onClick={handleCancel}
                data-prevent-routing
              >
                Cancel
              </button>

              <button
                type="button"
                className="px-4 py-3 text-sm text-center font-semibold text-pri dark:text-pri-d rounded-lg bg-red-500 hover:bg-red-600 flex flex-row items-center justify-center w-full gap-2 transition-colors duration-150"
                onClick={handleVote}
                disabled={lock}
                data-prevent-routing
              >
                Vote -{value}%
              </button>
            </div>
          </div>
        </div>
      </FloatingHTMLOverlay>
    </FloatingPortal>
  ) : isMobile ? (
    <FloatingPortal>
      <span
        className="!fixed inset-0 w-full h-full bg-black/30 backdrop-blur-[2px] z-[100]"
        onClick={() => setLikeToggle(false)}
      />
      <FloatingHTMLOverlay
        id="down-slider"
        onClick={ev => ev.stopPropagation()}
        className={cn(
          `z-1000 flex flex-col justify-between items-center gap-6 px-5 w-full h-fit min-h-[350px] !mt-auto !bottom-0 overflow-hidden border-t border-transparent rounded-xl
            font-semibold text-xl !fixed overflow-y-auto animate-slide-to-bottom
          bg-pri dark:bg-zinc-800 border border-pri dark:border-pri-d !inset-0
          `
        )}
        lockScroll
      >
        <span className="text-2xl font-bold py-4 pb-3 self-start">
          Down Vote {authorPerm.author}'s {isThread ? "thread" : isComment ? "Comment" : "post"}
        </span>

        <div className="flex flex-row flex-wrap w-full gap-3 pb-9">
          <button
            type="button"
            className={classNames(
              "flex flex-1 justify-center items-center py-4 px-4 min-w-[calc(50%-6px)] rounded-lg border border-pri dark:border-pri-d text-sm font-medium text-pri dark:text-pri-d transition-colors duration-150",
              {
                "bg-red-500 !border-red-500 !dark:border-red-500": value === 25
              }
            )}
            value={25}
            onClick={handleChange}
          >
            -25%
          </button>
          <button
            type="button"
            className={classNames(
              "flex flex-1 justify-center items-center py-4 px-4 min-w-[calc(50%-6px)] rounded-lg border border-pri dark:border-pri-d text-sm font-medium text-pri dark:text-pri-d transition-colors duration-150",
              {
                "bg-red-500 !border-red-500 !dark:border-red-500": value === 50
              }
            )}
            value={50}
            onClick={handleChange}
          >
            -50%
          </button>
          <button
            type="button"
            className={classNames(
              "flex flex-1 justify-center items-center py-4 px-4 min-w-[calc(50%-6px)] rounded-lg border border-pri dark:border-pri-d text-sm font-medium text-pri dark:text-pri-d transition-colors duration-150",
              {
                "bg-red-500 !border-red-500 !dark:border-red-500": value === 75
              }
            )}
            value={75}
            onClick={handleChange}
          >
            -75%
          </button>
          <button
            type="button"
            className={classNames(
              "flex flex-1 justify-center items-center py-4 px-4 min-w-[calc(50%-6px)] rounded-lg border border-pri dark:border-pri-d text-sm font-medium text-pri dark:text-pri-d transition-colors duration-150",
              {
                "bg-red-500 !border-red-500 !dark:border-red-500": value === 100
              }
            )}
            value={100}
            onClick={handleChange}
          >
            -100%
          </button>
        </div>

        <div className="flex flex-col w-full pb-4">
          <div className="relative flex flex-col w-full">
            <input
              list={id}
              className="absolute -top-[8px] h-2 w-full accent-red-500 appearance-none bg-transparent z-10"
              type="range"
              value={value}
              step={1}
              min={0}
              max={100}
              onChange={handleChange}
            />
            <div className="-top-[6px] relative h-1 w-full pointer-events-none rounded-full bg-gray-400">
              <div className="h-1 bg-red-500 rounded-full" style={{ width: `calc(${value}%)` }} />
            </div>
          </div>
          <div id={id} className="relative flex items-center w-full h-6 pt-2 text-center">
            {ranges.map(num => (
              <div
                key={num}
                className={classNames("flex w-[20%] select-none cursor-pointer z-0 font-sans font-semibold", {
                  "ml-[2px]": num === 0,
                  "pl-[5%]": num === 25,
                  "justify-center items-center": num === 50,
                  "pl-[12%]": num === 75,
                  "justify-end items-end mr-[2px]": num === 100
                })}
                style={{
                  left: `${num}%`
                }}
                onClick={() => setValue(num)}
              >
                <div
                  className={classNames(
                    "absolute -top-[1rem] bg-pri-d dark:bg-pri rounded-[3px] rotate-45 h-3 w-3 shadow-lg z-0",
                    {
                      "!bg-red-500 !dark:bg-red-500": value >= num && value !== 0
                    }
                  )}
                />
                {num}
              </div>
            ))}
          </div>
        </div>
        <div className="flex flex-1 flex-col justify-end items-center gap-y-2 w-full mt-auto pb-3">
          <button
            type="button"
            className="px-4 py-4 text-base text-center font-semibold text-pri dark:text-pri-d rounded-lg bg-red-500 hover:bg-red-600 flex flex-row items-center justify-center w-full gap-2"
            onClick={handleVote}
            disabled={lock}
            data-prevent-routing
          >
            Vote -{value}%
          </button>

          <button
            type="button"
            className="flex justify-center items-center w-full px-4 py-4 text-base text-center font-semibold text-pri dark:text-pri-d rounded-lg border border-pri dark:border-pri-d hover:bg-zinc-400 hover:dark:bg-zinc-600"
            onClick={handleCancel}
            data-prevent-routing
          >
            Cancel
          </button>
        </div>
      </FloatingHTMLOverlay>
    </FloatingPortal>
  ) : (
    <div
      id="down-slider"
      className={cn(
        "absolute w-full min-w-[310px] lg:min-w-[340px] bg-pri dark:bg-pri-d py-2.5 px-4 top-0 left-0 border border-pri dark:border-pri-d rounded-lg drop-shadow-lg shadow-[0_0_12px_3px_rgb(255_255_255_/_12%)] z-50",
        className
      )}
      onClick={ev => {
        ev?.stopPropagation();
        ev?.preventDefault();
      }}
      data-prevent-routing
    >
      <div className="flex flex-col w-full py-4">
        <div className="relative flex flex-col w-full">
          <input
            list={id}
            className="absolute -top-[10px] h-2 w-full accent-red-500 appearance-none bg-transparent z-10"
            type="range"
            value={value}
            step={1}
            min={0}
            max={100}
            onChange={handleChange}
          />
          <div className="-top-[8px] relative h-1 w-full pointer-events-none rounded-full bg-gray-400">
            <div className="h-1 bg-red-500 rounded-full" style={{ width: `calc(${value}%)` }} />
          </div>
        </div>
        <div id={id} className="relative flex items-center w-full h-6 pt-2 text-center">
          {ranges.map(num => (
            <div
              key={num}
              className={classNames(
                "flex w-[20%] select-none cursor-pointer z-0 font-sans font-semibold text-red-500",
                {
                  "ml-[2px]": num === 0,
                  "pl-[5%]": num === 25,
                  "justify-center items-center": num === 50,
                  "pl-[12%]": num === 75,
                  "justify-end items-end mr-[2px]": num === 100
                }
              )}
              style={{
                left: `${num}%`
              }}
              onClick={() => setValue(num)}
            >
              <div
                className={classNames("absolute -top-[1rem] rounded-[3px] rotate-45 h-3 w-3 shadow-lg z-0", {
                  "bg-gray-500 dark:bg-pri": value < num,
                  "!bg-red-500": value >= num && value !== 0
                })}
              />
              -{num}
            </div>
          ))}
        </div>
      </div>

      <div className="flex items-center gap-x-2 w-full mt-auto pb-3">
        <button
          type="button"
          className="flex justify-center items-center w-full px-4 py-3 text-sm text-center font-semibold text-pri dark:text-pri-d rounded-lg border border-pri dark:border-pri-d hover:bg-pri-hov hover:dark:bg-pri-hov-d transition-colors duration-150"
          onClick={handleCancel}
          data-prevent-routing
        >
          Cancel
        </button>

        <button
          type="button"
          className="px-4 py-3 text-sm text-center font-semibold text-pri dark:text-pri-d rounded-lg bg-red-500 hover:bg-red-600 flex flex-row items-center justify-center w-full gap-2 transition-colors duration-150"
          onClick={handleVote}
          disabled={lock}
          data-prevent-routing
        >
          Vote -{value}%
        </button>
      </div>
    </div>
  );
}
