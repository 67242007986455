import { faEllipsisH, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useMemo, Suspense, memo, useRef } from "react";
import { PostInteractions } from "~/components/posts/PostInteractions";
import { ClientOnly } from "remix-utils/client-only";
import { useAppStore } from "~/store";
import { buildLinksImagesArray, isImageLink, useParsedBody } from "~/utils/markdown";
import AccountName from "../format/AccountName";
import DisplayName from "../format/DisplayName";
import Reputation from "../format/Reputation";
import { SmallAvatar12, SmallAvatar14 } from "../format/SmallAvatar";
import ImageViewer from "../ImageViewer";
import { TimeSincePublish } from "../TimeSincePublish";
import LoadOnlyReply, {
  ThreadBody,
  ThreadContainer,
  ThreadFooter,
  ThreadHeader,
  ThreadText
} from "./ThreadViewHelpers";
import ProfileLink from "~/components/ProfileLink";
import ThreadOptions from "~/components/threads/ThreadOptions";
import PollView from "~/components/format/PollView";
import { type Thread } from "~/utils/leocache";
import { DecryptLoader } from "../general/DecryptLoader";
import { SubscribeToCreatorThread } from "../general/SubscribeToCreator";
import ImagesGrid from "../format/ImagesGrid";
import { useDecryption } from "~/hooks/useDecryption";
import { ThreadActions } from "./ThreadActions";
import ReplyCount from "../format/ReplyCount";
import { useNavigate } from "@remix-run/react";

interface FullThreadProps {
  threadContent: Thread;
  inView?: boolean;
  liveMode?: boolean;
  continues?: boolean;
  norecursive?: boolean;
}

function DeletedFocusThread({ threadIndexed }) {
  const { author } = threadIndexed;
  return (
    <ThreadFocusContainer>
      <ThreadFocusHeader>
        <SmallAvatar12 author={author} />
        <AccountName author={author} />
      </ThreadFocusHeader>
      <ThreadBody>
        <span className="text-red-500 dark:text-red-800">This thread is deleted.</span>
      </ThreadBody>
    </ThreadFocusContainer>
  );
}

interface ThreadViewProps {
  threadContent: Thread;
  inView: boolean;
  liveMode?: boolean;
}

interface ThreadHeaderProps {
  children: React.ReactNode;
}

function ThreadFocusHeader({ children }: ThreadHeaderProps) {
  return <div className="flex gap-x-3">{children}</div>;
}

export const ThreadFocusView = memo(({ threadContent }: ThreadViewProps) => {
  if (!threadContent || threadContent?.[0] === false) {
    return <FullFocusThreadLoading />;
  }

  if (threadContent.deleted) {
    return <DeletedFocusThread threadIndexed={threadContent.thread} />;
  }

  if (Array.isArray(threadContent) && threadContent.length > 0) {
    return <FullFocusThread threadContent={threadContent[0]} inView={false} />;
  }

  return <FullFocusThread threadContent={threadContent} inView={false} />;
});
ThreadFocusView.displayName = "ThreadFocusView";

function ThreadFocusContainer({ children }) {
  return <div className="flex flex-col px-3 py-2 border-b border-pri dark:border-pri-d gap-y-3">{children}</div>;
}

const FullFocusThreadLoading = () => {
  const navigate = useNavigate();

  return (
    <ThreadContainer className="cursor-default sm:hover:bg-transparent sm:dark:hover:bg-transparent border-b border-pri dark:border-pri-d">
      <div className="flex flex-1 flex-row items-start gap-x-5 py-2">
        <div className="relative flex shrink-0">
          <span className="flex shrink-0 size-14 rounded-full bg-gray-200 dark:bg-zinc-800" />
          <div className="absolute -bottom-0.5 -right-0.5">
            <Reputation reputation={0} />
          </div>
        </div>

        <ThreadBody className="flex-1">
          <ThreadText>
            <div className="flex flex-col flex-1 justify-start items-start gap-y-1 pt-1 text-start">
              <h2 className="font-bold !my-0">Waiting for thread.</h2>
              <p className="!my-0 text-base text-pri/60 dark:text-pri-d/60">
                This thread is not indexed yet, please wait few seconds and refresh page.
              </p>

              <button
                type="button"
                className="flex justify-center items-center gap-x-2 rounded-xl my-3 pl-3 pr-4 py-2 bg-gray-300 dark:bg-zinc-700 text-sm font-semibold transition-opacity duration-150 hover:opacity-90 cursor-pointer"
                onClick={() => navigate(0)}
              >
                <FontAwesomeIcon icon={faRefresh} size="sm" fixedWidth />
                <span>Reload</span>
              </button>
            </div>
          </ThreadText>
          <ThreadFooter>
            <PostInteractions className="relative flex gap-x-4 !p-none -ml-1 pointer-events-none opacity-50">
              <ReplyCount replyCount={0} threadContent={undefined} />

              <ThreadActions
                content={null}
                fake={false}
                downvote={false}
                rethread={false}
                editThread={false}
                setDownvote={() => {}}
                setRethread={() => {}}
                setEditThread={() => {}}
              />
            </PostInteractions>
          </ThreadFooter>
        </ThreadBody>
      </div>
    </ThreadContainer>
  );
};

export function FullFocusThread({ threadContent }: FullThreadProps) {
  const thread = threadContent;
  const { author, permlink, children, fake, author_reputation: reputation, created, json_metadata: metadata } = thread;

  const {
    decryptedBody,
    decrypting,
    decryptionFailed,
    images: decryptedImages
  } = useDecryption(thread, metadata?.encrypted ?? false, true);

  const parsedBody = useParsedBody(decryptedBody);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const [rethread, setRethread] = useState(false);
  const [downvote, setDownvote] = useState(false);
  const [editThread, setEditThread] = useState(false);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const threadOptionsRef = useRef<HTMLDivElement>(null);

  const authorPerm = { author, permlink };

  const isPoll = (metadata?.isPoll && metadata?.pollOptions) || false;

  const isParentThread = thread?.parent_author === "leothreads";

  const images = useMemo(() => {
    if (!metadata && !decryptedImages) return [];

    const links = metadata?.links?.filter(isImageLink) || [];

    const modifyImageUrls = (url: string) => {
      return url.replaceAll(")", "").replaceAll("(", "");
    };

    const merged = links.concat(decryptedImages ?? []).map(modifyImageUrls);

    return merged;
  }, [metadata, decryptedImages]);

  const handleImageClick = (e: React.MouseEvent) => {
    if ((e.target as HTMLElement).tagName !== "IMG" || (e.target as HTMLElement).nodeName !== "IMG" || isViewerOpen)
      return;

    const index = images.findIndex(img => (e.target as HTMLImageElement).src.includes(img));

    setCurrentIndex(index);
    setIsViewerOpen(true);
  };

  return (
    <div id="post" onClick={e => handleImageClick(e)}>
      <ThreadFocusContainer>
        {!isParentThread && (
          <div className="relative overflow-hidden h-[150px] rounded-lg border border-pri dark:border-pri-d">
            <ClientOnly>
              {() => (
                <LoadOnlyReply
                  author={thread.parent_author}
                  permlink={thread.parent_permlink}
                  notop={false}
                  continues={false}
                />
              )}
            </ClientOnly>
          </div>
        )}

        <ThreadFocusHeader>
          <ThreadAuthorAvatar author={author} reputation={reputation} />
          <div className="flex flex-col justify-center">
            <ThreadHeader>
              <ProfileLink accountName={author} referrer={thread.permlink}>
                <DisplayName authorName={author} name={thread.author} />
              </ProfileLink>
            </ThreadHeader>
            <div className="flex flex-row items-center gap-1">
              <ProfileLink accountName={author}>
                <AccountName author={author} />
              </ProfileLink>
              <span className="flex text-gray-600 dark:text-gray-400"> · </span>
              <TimeSincePublish publishTime={created} utc={false} />
            </div>
          </div>

          <div ref={threadOptionsRef} className="flex items-center justify-center ml-auto">
            <button
              type="button"
              aria-label="Thread Options"
              className="z-10 relative flex justify-center items-center size-10 rounded-full hover:bg-pri-d/[.1] dark:hover:bg-pri/[.1] transition-colors"
              onClick={() => setOptionsVisible(current => !current)}
              data-prevent-routing
            >
              <FontAwesomeIcon
                icon={faEllipsisH}
                className="text-gray-600 dark:text-gray-400 pointer-events-none select-none"
              />
            </button>

            {optionsVisible && (
              <ThreadOptions
                ref={threadOptionsRef}
                threadContent={threadContent}
                visible={optionsVisible}
                setVisible={setOptionsVisible}
                setRethread={setRethread}
                setDownvote={setDownvote}
                setEditThread={setEditThread}
              />
            )}
          </div>
        </ThreadFocusHeader>
        <ThreadBody>
          <ThreadText>
            {decrypting ? (
              <DecryptLoader />
            ) : decryptionFailed ? (
              <SubscribeToCreatorThread creator={thread.author} />
            ) : (
              parsedBody
            )}
            <ImagesGrid images={images} />
            {isPoll ? (
              <Suspense fallback={<></>}>
                <PollView authorPerm={authorPerm} pollOptions={metadata?.pollOptions} />
              </Suspense>
            ) : null}
          </ThreadText>
          <ThreadFooter>
            <PostInteractions className="relative">
              <ReplyCount replyCount={children} threadContent={thread} />

              <ThreadActions
                content={thread}
                fake={fake}
                downvote={downvote}
                rethread={rethread}
                editThread={editThread}
                setDownvote={setDownvote}
                setRethread={setRethread}
                setEditThread={setEditThread}
              />
            </PostInteractions>
          </ThreadFooter>
        </ThreadBody>

        <ImageViewer
          visible={isViewerOpen}
          images={images}
          currentIndex={currentIndex}
          onClose={() => setIsViewerOpen(false)}
        />
      </ThreadFocusContainer>
    </div>
  );
}

interface ThreadAuthorAvatar {
  author: any;
  reputation: number;
}

function ThreadAuthorAvatar({ author, reputation }: ThreadAuthorAvatar) {
  const threadcasts = useAppStore(store => store.discussion.threadcasts);

  const in_threadcast = threadcasts?.find?.(
    t => t.author === author || t.replies.find(r => r.split("/")[0] === author)
  );
  return (
    <div className="relative flex">
      <SmallAvatar14 author={author} />
      {!in_threadcast && (
        <div className="absolute z-10 -right-0.5 -bottom-0.5">
          <Reputation reputation={reputation} />
        </div>
      )}
    </div>
  );
}

