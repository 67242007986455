import { useHiveContent, HiveFetchState } from "~/hooks/contentHooks";
import type { ThreadIndexed } from "~/utils/thread";
import { FullThread } from "./FullThread";
import { LoadingThread } from "./LoadingThreads";
import { cn } from "~/utils/cn";
import type { Thread } from "~/utils/leocache";

interface OnlyReplyProps {
  author: string;
  permlink: string;
  notop: boolean;
  continues: boolean;
  thread?: Thread | undefined;
  feedType?: string;
}

export default function LoadOnlyReply({ author, permlink, continues, notop, thread, feedType }: OnlyReplyProps) {
  const [state, threadContent] = useHiveContent({ author, permlink }, thread !== undefined);

  if (thread === undefined && (state === HiveFetchState.Initial || !threadContent)) {
    return <LoadingThread threadIndexed={{ author, permlink } as ThreadIndexed} />;
  }

  return (
    <div className="flex flex-col relative">
      {!notop && (
        <div
          className="w-[1px]
            sm:left-[39.5px] md:left-[39.5px] left-[34.5px] pc:left-[39.5px] -top-4
            -z-10 h-7 absolute bg-zinc-300 dark:bg-zinc-700 rounded-full "
        />
      )}
      <FullThread
        continues={continues}
        norecursive={true}
        threadContent={thread || threadContent}
        feedType={feedType}
      />
    </div>
  );
}

interface ThreadContainerProps {
  children: React.ReactNode;
  className?: string;
}
export function ThreadContainer({ children, className }: ThreadContainerProps) {
  return (
    <div
      className={cn(
        "flex py-3.5 px-3 sm:hover:bg-pri-hov sm:dark:hover:bg-pri-hov-d cursor-pointer transition-colors",
        className
      )}
    >
      {children}
    </div>
  );
}

interface ThreadBodyProps {
  children: React.ReactNode;
  className?: string;
}

export function ThreadBody({ children, className }: ThreadBodyProps) {
  return <div className={cn("relative flex-1 min-w-0 flex flex-col gap-y-2", className)}>{children}</div>;
}

interface ThreadHeaderProps {
  children: React.ReactNode;
}

export function ThreadHeader({ children }: ThreadHeaderProps) {
  return <div className="h-max flex flex-row justify-between gap-x-2 items-center">{children}</div>;
}

interface ThreadFooterProps {
  children: React.ReactNode;
  className?: string;
}

export function ThreadFooter({ children, className }: ThreadFooterProps) {
  return (
    <div onClick={ev => ev.stopPropagation()} className={cn("flex items-center gap-1 pt-3 my-0.5", className)}>
      {children}
    </div>
  );
}

interface ThreadTextProps {
  className?: string;
  children: React.ReactNode;
}

export function ThreadText({ className, children }: ThreadTextProps) {
  return (
    <div
      className={cn(
        "px-0.5 list-disc prose dark:prose-invert prose-lg sm:prose-sm prose-headings:mt-0 break-words min-w-full max-w-0 !text-lg",
        className
      )}
    >
      {children}
    </div>
  );
}
