import { Upload } from "tus-js-client";
import { getCookie } from "../cookie";

export const THREE_SPEAK_BASE_URL = "https://studio.3speak.tv/mobile";
const UPLOAD_URL = "https://uploads.3speak.tv/files";

import { PrivateKey, Memo } from "@hiveio/dhive";

// Types for function responses and parameters
interface AccessTokenResponse {
  memo: string;
}

interface UploadResponse {
  file: File;
  url: string;
}

interface VideoDetails {
  filename: string;
  oFilename: string;
  size: number;
  duration: number;
  thumbnail: string;
  owner: string;
}

interface VideoStatusResponse {
  firstUpload: boolean;
  _id: string;
  originalFilename: string;
  permlink: string;
  duration: number;
  size: number;
  owner: string;
}

// Utility functions for 3Speak interactions
export const getAccessToken = async (username: string): Promise<AccessTokenResponse> => {
  const response = await fetch(`${THREE_SPEAK_BASE_URL}/login?username=${username}`);
  if (!response.ok) {
    throw new Error("Failed to fetch access token");
  }
  return response.json();
};

export const get3spkCookie = async (username: string, accessToken: string): Promise<string> => {
  const response = await fetch(`${THREE_SPEAK_BASE_URL}/login?username=${username}&access_token=${accessToken}`);

  if (!response.ok) {
    throw new Error("Failed to fetch cookie");
  }
  const setCookieHeader = response.headers.get("Set-Cookie");

  return setCookieHeader ? setCookieHeader : "";
};

export const uploadFile = (file: File, onProgress: (percentage: number) => void): Promise<UploadResponse> => {
  return new Promise((resolve, reject) => {
    const upload = new Upload(file, {
      endpoint: UPLOAD_URL,
      retryDelays: [0, 1000, 3000, 5000],
      onError: error => reject(error),
      onProgress: (bytesUploaded, bytesTotal) => {
        const percentage = (bytesUploaded / bytesTotal) * 100;
        onProgress(percentage);
      },
      onSuccess: () => {
        console.log({ upload });
        resolve({ file, url: upload.url || "" });
      }
    });
    upload.start();
  });
};

export const updateVideoDetails = async (details: VideoDetails, cookie: string): Promise<VideoStatusResponse> => {
  console.log({ cook: cookie });
  const response = await fetch(`${THREE_SPEAK_BASE_URL}/api/update_info`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${cookie}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(details)
  });

  if (!response.ok) {
    throw new Error("Failed to update video details");
  }

  return response.json();
};

export const getUserVideos = async (cookie: string): Promise<any> => {
  const response = await fetch(`${THREE_SPEAK_BASE_URL}/api/my-videos`, {
    method: "GET",
    headers: {
      Cookie: cookie,
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error("Failed to fetch user videos");
  }

  return response.json();
};

export const publishVideo = async (cookie: string, _id: string): Promise<any> => {
  const response = await fetch(`https://studio.3speak.tv/mobile/api/my-videos/iPublished`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${cookie}`,
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      videoId: _id
    })
  });

  if (!response.ok) {
    throw new Error("Failed to fetch user videos");
  }

  return response.json();
};

export const updateVideoPost = async (
  videoId: string,
  title: string,
  description: string,
  tags: string,
  thumbnail: string,
  isNsfwContent: boolean,
  cookie: string
): Promise<VideoStatusResponse> => {
  const requestBody = {
    videoid: videoId,
    title,
    description,
    isNsfwContent,
    tags,
    thumbnail
  };

  const response = await fetch(`${THREE_SPEAK_BASE_URL}/api/upload_info`, {
    method: "POST",
    headers: {
      Cookie: cookie,
      "Content-Type": "application/json"
    },
    body: JSON.stringify(requestBody)
  });

  if (!response.ok) {
    throw new Error("Failed to update video post");
  }

  return response.json();
};

export const decodeMemo = (memo: string): {} => {
  try {
    return JSON.parse(atob(memo));
  } catch (e) {
    console.log({ spkError: e });
    return {};
  }
};

// Function to decrypt the memo
export const decryptMemo = async (memo: string, name: string): string => {
  const { auth, proxy } = await getCookie("__session");

  console.log({ proxy });
  if (proxy === "leolock") {
    const { posting_key, active_key } = JSON.parse(auth);
    const privateKey = PrivateKey.fromString(posting_key);
    const decryptedMemo = Memo.decode(privateKey, memo);
    return decryptedMemo.substring(1);
  }

  if (proxy === "keychain") {
    return await new Promise(resolve => {
      (window as any).hive_keychain.requestVerifyKey(name, memo, "Posting", data => {
        resolve(data.result.substring(1));
      });
    });
  }
};

export const getMyVideos = async (cookie: string): Promise<any> => {
  const response = await fetch(`${THREE_SPEAK_BASE_URL}/api/my-videos`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${cookie}`,
      "Content-Type": "application/json"
    }
  });

  if (!response.ok) {
    throw new Error("Failed to fetch my videos");
  }

  return response.json();
};
