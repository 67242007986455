import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PrefetchPageLinks, useLocation, useNavigate, useSearchParams } from "@remix-run/react";
import classNames from "classnames";
import React, { useState, useMemo, memo, useRef } from "react";
import { isImageLink, useParsedBody, useStrippedSlicedBody } from "~/utils/markdown";
import AccountName from "../format/AccountName";
import DisplayName from "../format/DisplayName";
import PollView from "../format/PollView";
import { PostInteractions } from "~/components/posts/PostInteractions";
import ReplyCount from "../format/ReplyCount";
import Reputation from "../format/Reputation";
import { SmallAvatar12, SmallAvatar14 } from "../format/SmallAvatar";
import { TimeSincePublish } from "../TimeSincePublish";
import { LoadMoreThread } from "./LoadMoreThread";
import { ThreadFirstChild } from "./ThreadFirstChild";
import LoadOnlyReply, {
  ThreadContainer,
  ThreadBody,
  ThreadHeader,
  ThreadText,
  ThreadFooter
} from "./ThreadViewHelpers";
import ProfileLink from "~/components/ProfileLink";
import ControlledThreadOptions from "./ControlledThreadOptions";
import ThreadOptions from "./ThreadOptions";
import type { Thread } from "~/utils/leocache";
import { SubscribeToCreatorThread } from "../general/SubscribeToCreator";
import { DecryptLoader } from "../general/DecryptLoader";
import ImagesGrid from "../format/ImagesGrid";
import { useCanComment, useDecryption } from "~/hooks/useDecryption";
import { ThreadActions } from "./ThreadActions";
import { cn } from "~/utils/cn";
import { useOnRouteChange } from "~/hooks/useOnRouteChange";

interface FullThreadProps {
  threadContent: Thread;
  continues?: boolean;
  norecursive?: boolean;
  fake?: boolean;
  beforeTransition?: Function;
  feedType?: string;
  noParent?: boolean;
  display?: "default" | "quick";
}

export const FullThread = memo(
  ({ threadContent, continues, norecursive, fake, feedType, noParent, beforeTransition, display }: FullThreadProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [, setSearchParams] = useSearchParams();

    const {
      author,
      children,
      permlink,
      author_reputation: reputation,
      created,
      json_metadata: _metadata
    } = threadContent;

    const metadata = useMemo(() => {
      if (typeof _metadata !== "string") return _metadata;

      try {
        return JSON.parse(_metadata);
      } catch {
        return _metadata;
      }
    }, [_metadata]);

    const threadOptionsRef = useRef<HTMLDivElement>(null);
    const [optionsVisible, setOptionsVisible] = useState(false);

    const encrypted = metadata?.encrypted ?? false;

    const {
      decryptedBody,
      decrypting,
      decryptionFailed,
      images: decryptedImages
    } = useDecryption(threadContent, encrypted, true);

    const canComment = useCanComment(threadContent.author, encrypted);

    const shouldSlice =
      (decryptedBody || "").length > 600 &&
      typeof threadContent?.is_premium === "boolean" &&
      !threadContent.is_premium &&
      !encrypted;

    const slicedBody = useStrippedSlicedBody(decryptedBody || "", 240);
    const parsedBody = useParsedBody(decryptedBody);

    const [rethread, setRethread] = useState(false);
    const [downvote, setDownvote] = useState(false);
    const [editThread, setEditThread] = useState(false);

    const handleClick = (e: React.MouseEvent) => {
      if (!document.getSelection()?.isCollapsed) {
        return;
      }

      if (typeof beforeTransition === "function") {
        beforeTransition();
      }

      if ((e.target as HTMLElement).hasAttribute("data-prevent-routing")) {
        return;
      }

      if (
        ((e.target as HTMLElement).tagName === "IMG" || (e.target as HTMLElement).nodeName === "IMG") &&
        !(e.target as HTMLElement).hasAttribute("data-prevent-view")
      ) {
        e.preventDefault();
        e.stopPropagation();

        const index = (e.target as HTMLElement).dataset["index"];

        setSearchParams(
          params => {
            params.set("view", `${author}/${permlink}`);
            return params;
          },
          {
            preventScrollReset: true,
            state: {
              thread: threadContent,
              index: Number(index || "0")
            }
          }
        );
        return;
      }

      if (e.ctrlKey || e.metaKey) {
        window.open(`https://inleo.io/threads/view/${threadContent?.author}/${threadContent?.permlink}`, "_blank");
      } else {
        navigate(`/threads/view/${author}/${permlink}`);
      }
    };

    useOnRouteChange(() => {
      if (typeof beforeTransition === "function") {
        beforeTransition();
      }
    });

    const authorPerm = useMemo(() => ({ author, permlink }), [author, permlink]);

    const { isPostsPage, isNotificationsPage, isFollowingPage, isThreadFullView } = useMemo(() => {
      const pathname = location?.pathname;

      return {
        isPostsPage: pathname?.startsWith("/@"),
        isNotificationsPage: pathname === "/notifications",
        isFollowingPage: pathname === "/threads/following" || pathname === "/threads/foryou",
        isThreadFullView: pathname?.startsWith("/threads/view"),
        isForYou: feedType === "foryou"
      };
    }, [location?.pathname]);

    const isForYou = useMemo(() => feedType === "foryou", [feedType]);

    const first_child = useMemo(() => {
      let _first_child = (threadContent?.replies || [])?.at(0)?.replaceAll("@")?.split("/") || [];

      let first_child = _first_child.length
        ? {
            author: _first_child[0],
            permlink: _first_child[1]
          }
        : false;

      return first_child;
    }, [threadContent]);

    const isFirstReplyOwners = useMemo(() => first_child?.author === author, [first_child, author]);

    const isRepliesFewer = useMemo(
      () =>
        threadContent?.replies?.length <= 3 &&
        threadContent?.replies?.length > 1 &&
        isFirstReplyOwners === false &&
        !isNotificationsPage,
      [threadContent?.replies, isFirstReplyOwners, isNotificationsPage]
    );

    const isOnlyReply = useMemo(
      () => threadContent?.replies?.length! === 1 && isFirstReplyOwners === false && !isNotificationsPage,
      [threadContent?.replies, isFirstReplyOwners, isNotificationsPage]
    );

    const isParentThread = useMemo(
      () => threadContent?.parent_author === "leothreads" && !isNotificationsPage,
      [threadContent?.parent_author, isNotificationsPage]
    );

    const excludedDomains = useMemo(() => ["https://labs.inleo.io/threads", "https://inleo.io/threads"], []);

    const hasLinks =
      metadata?.links?.length! > 0
        ? metadata?.links?.every(link => !excludedDomains?.some(domain => link?.includes(domain)))
        : false;

    const images = useMemo(() => {
      if (!metadata && !decryptedImages) return [];

      const links = metadata?.links?.filter(isImageLink) || [];

      const modifyImageUrls = (url: string) => {
        return url.replaceAll(")", "").replaceAll("(", "");
      };

      const merged = links.concat(decryptedImages ?? []).map(modifyImageUrls);

      return merged;
    }, [metadata, decryptedImages]);

    const isPoll = metadata?.isPoll && metadata?.pollOptions;

    const handleParentClick = (ev: React.MouseEvent) => {
      ev.stopPropagation();
      navigate(`/threads/view/${threadContent?.parent_author}/${threadContent?.parent_permlink}`);
    };

    const [controlledThreadOptions, setControlledThreadOptions] = React.useState(false);

    const [cursor, setCursor] = React.useState({ x: 0, y: 0 });

    const [prefetch, setPrefetch] = React.useState(false);

    // hide_links
    if (
      !isPostsPage &&
      hasLinks &&
      !location.pathname?.startsWith("/threads/view/") &&
      !location.pathname?.startsWith("/profile") &&
      !location.pathname?.startsWith("/notifications") &&
      !location.pathname?.startsWith("/threads/following") &&
      !location.pathname?.startsWith("/threads/foryou")
    ) {
      return <div className="h-[1px] max-h-[1px] opacity-0"></div>;
    }

    if (noParent !== true) {
      if (threadContent?.parent_author && threadContent?.parent_permlink && !isThreadFullView) {
        if (threadContent.parent_author !== "leothreads" && norecursive !== true) {
          return (
            <>
              <LoadOnlyReply
                author={threadContent?.parent_author!}
                permlink={threadContent?.parent_permlink}
                continues={true}
                notop={true}
                feedType={feedType}
              />
              <LoadOnlyReply
                author={threadContent?.author!}
                permlink={threadContent?.permlink}
                thread={threadContent}
                continues={false}
                notop={false}
                feedType={feedType}
              />
            </>
          );
        }
      }
    }

    return (
      <div
        key={`${threadContent?.permlink}-view`}
        className={classNames("relative min-h-px", {
          "border-b border-pri dark:border-pri-d group sm:hover:bg-pri-hov sm:dark:hover:bg-pri-hov-d":
            !(isFirstReplyOwners || isRepliesFewer || isOnlyReply || continues) || !canComment
        })}
        onClick={ev => (isFollowingPage && !isParentThread ? handleParentClick(ev) : handleClick(ev))}
        onMouseEnter={() => setPrefetch(true)}
        onContextMenu={event => {
          event.preventDefault();
          setCursor({
            x: event.clientX + 6,
            y: event.clientY + 6
          });
          setControlledThreadOptions(true);
        }}
      >
        {prefetch && <PrefetchPageLinks page={`/threads/view/${author}/${permlink}`} />}
        <ThreadContainer className={cn("!gap-x-0", { "pt-2 pb-0.5": display === "quick" })}>
          {controlledThreadOptions && display !== "quick" ? (
            <ControlledThreadOptions
              threadContent={threadContent}
              open={controlledThreadOptions}
              setOpen={setControlledThreadOptions}
              cursor={cursor}
              setRethread={setRethread}
              setDownVote={setDownvote}
              setEditThread={setEditThread}
            />
          ) : null}

          <div className="flex flex-col !justify-between pr-4">
            <div className="relative">
              {display === "quick" ? (
                <SmallAvatar12 author={author} disableThreadcast />
              ) : (
                <SmallAvatar14 author={author} disableThreadcastIndicator />
              )}

              <div className="absolute -bottom-0.5 -right-0.5">
                <Reputation reputation={reputation} />
              </div>
            </div>
            {(isFirstReplyOwners || isRepliesFewer || isOnlyReply || continues) && canComment && (
              <div className="w-[1px] -z-30 -mt-6 pt-2 h-full bg-zinc-300 dark:bg-zinc-700 rounded-full self-center" />
            )}
          </div>
          <ThreadBody>
            <ThreadHeader>
              <div className="flex flex-row mt-1">
                <div className="flex flex-col">
                  <div className="flex flex-row items-center gap-2">
                    <ProfileLink
                      accountName={author}
                      referrer={threadContent?.permlink}
                      displayName={threadContent?.display_name}
                      subscriptions={threadContent?.subscriptions}
                      premium={threadContent?.is_premium}
                    >
                      <DisplayName
                        authorName={author}
                        name={threadContent?.display_name}
                        premium={threadContent.is_premium}
                        subscriptions={threadContent.subscriptions}
                      />
                    </ProfileLink>
                  </div>

                  <div
                    className={cn("flex flex-row items-center gap-1 pt-px", {
                      "pt-1": isForYou
                    })}
                  >
                    <AccountName author={author} />

                    {!isForYou ? (
                      <>
                        <span className="flex text-gray-600 dark:text-gray-400">·</span>
                        <TimeSincePublish publishTime={created} utc={false} />
                      </>
                    ) : null}
                  </div>
                </div>
              </div>

              {display !== "quick" ? (
                <div ref={threadOptionsRef}>
                  <button
                    type="button"
                    aria-label="Thread Options"
                    className="z-10 relative flex justify-center items-center size-10 rounded-full hover:bg-pri-d/[.1] dark:hover:bg-pri/[.1] transition-colors"
                    onClick={() => setOptionsVisible(current => !current)}
                    data-prevent-routing
                  >
                    <FontAwesomeIcon
                      icon={faEllipsisH}
                      className="text-gray-600 dark:text-gray-400 pointer-events-none select-none"
                    />
                  </button>

                  {optionsVisible ? (
                    <ThreadOptions
                      ref={threadOptionsRef}
                      threadContent={threadContent}
                      visible={optionsVisible}
                      setVisible={setOptionsVisible}
                      setRethread={setRethread}
                      setDownvote={setDownvote}
                      setEditThread={setEditThread}
                    />
                  ) : null}
                </div>
              ) : null}
            </ThreadHeader>
            {!isParentThread && noParent !== true ? (
              <span className="relative flex items-center pb-3 gap-x-2">
                <span className="w-6 h-[1px] bg-gray-300 dark:bg-zinc-600 rounded-lg" />
                <span className="text-xs text-gray-400 dark:text-zinc-500 hover:underline" onClick={handleParentClick}>
                  Replying to <span className="text-acc font-bold">@{threadContent.parent_author}</span>
                </span>
              </span>
            ) : null}

            <ThreadText className={display === "quick" ? "!text-base" : ""}>
              <div>
                {decrypting ? (
                  <DecryptLoader />
                ) : decryptionFailed ? (
                  <SubscribeToCreatorThread creator={threadContent.author} />
                ) : shouldSlice ? (
                  slicedBody
                ) : (
                  parsedBody
                )}
                <ImagesGrid images={images} />
              </div>

              {shouldSlice ? (
                <React.Fragment>
                  <span className="absolute w-full h-20 bg-gradient-to-t from-pri/90 dark:from-pri-d/90 dim:!from-black/90 to-transparent -mt-20" />
                  <div className="z-[1] flex items-center py-2 cursor-pointer font-medium text-acc w-full">
                    Show More
                  </div>
                </React.Fragment>
              ) : null}

              {isPoll && <PollView authorPerm={authorPerm} pollOptions={metadata?.pollOptions} />}
            </ThreadText>
            <ThreadFooter>
              <PostInteractions
                className={cn("relative flex gap-x-4 !p-none -ml-1", {
                  "scale-90 origin-left": display === "quick"
                })}
              >
                <ReplyCount replyCount={children} threadContent={threadContent} />

                <ThreadActions
                  content={threadContent}
                  fake={fake}
                  downvote={downvote}
                  rethread={rethread}
                  editThread={editThread}
                  setDownvote={setDownvote}
                  setRethread={setRethread}
                  setEditThread={setEditThread}
                />
              </PostInteractions>
            </ThreadFooter>
          </ThreadBody>
        </ThreadContainer>

        {isFirstReplyOwners && <ThreadFirstChild author={author} />}
        {isRepliesFewer && canComment && (
          <LoadMoreThread
            author={author}
            permlink={permlink}
            children={children}
            replies={threadContent?.replies || []}
            firstReplyAuthor={first_child?.author! || author}
          />
        )}
        {first_child && isOnlyReply && canComment && (
          <LoadOnlyReply
            author={first_child?.author!}
            permlink={first_child?.permlink!}
            thread={threadContent?.only_reply?.[0]}
            feedType={feedType}
          />
        )}
      </div>
    );
  }
);

FullThread.displayName = "FullThread";
