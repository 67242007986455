import type { Dispatch, SetStateAction } from "react";
import { useAppStore } from "~/store";
import { toast } from "react-toastify";
import { FloatingHTMLOverlay } from "~/components/FloatingHTMLOverlay";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import type { AccountSession } from "~/utils/transactions";
import { broadcastAll } from "~/utils/transactions";
import { KeychainKeyType } from "~/utils/keychain";

interface AddBeneficiariesModalProps {
  authority?: string;
  visibility: boolean;
  setVisibility: Dispatch<SetStateAction<boolean>>;
  hideModal: () => void;
}

export default function PostingAuthorityModal({
  authority,
  visibility,
  setVisibility,
  hideModal
}: AddBeneficiariesModalProps) {
  const activeAccount = useAppStore(store => store.account.activeAccount);
  const session = useAppStore(store => store.account.keys);

  const setAuthorityTrue = useAppStore(store => store.markdown.setAuthorityTrue);

  const handleGrant = async () => {
    if (!activeAccount) return;

    switch (session.proxy) {
      case "leolock": {
        if (!session.postingKey || !session.activeKey) {
          return toast("Couldn't find account keys, please sign-in again.", {
            type: "error",
            autoClose: 3000
          });
        }

        const s: AccountSession = {
          proxy: "leolock",
          active_key: session.activeKey,
          posting_key: session.postingKey
        };

        const posting = activeAccount.posting;

        const operation = [
          "account_update2",
          {
            account: activeAccount.name,
            json_metadata: JSON.stringify(activeAccount.json_metadata),
            posting_json_metadata: JSON.stringify(activeAccount.posting_json_metadata),
            posting: {
              ...posting,
              account_auths: [...posting.account_auths, ["leofinance", 1]]
            },
            extensions: []
          }
        ];

        try {
          await broadcastAll({
            author: activeAccount.name,
            type: KeychainKeyType.Active,
            operations: [operation],
            session: s
          });
          setAuthorityTrue();
          setVisibility(false);
        } catch (error) {
          toast(error.message || "Something went wrong while granting authority", {
            type: "error",
            autoClose: 3000
          });
        }
        break;
      }

      case "hivesigner": {
        window.open(
          "https://hivesigner.com/authorize/leofinance?redirect_uri=https://inleo.io",
          "popup",
          "width=600,height=600"
        );

        setTimeout(() => {
          setAuthorityTrue();

          setTimeout(() => setVisibility(false), 500);
        }, 5000);
        break;
      }

      case "keychain": {
        if ((window as any).hive_keychain) {
          new Promise((resolve, reject) => {
            (window as any).hive_keychain.requestAddAccountAuthority(
              name,
              authority ? authority : "leofinance",
              "Posting",
              1,
              result => {
                if (result.success) {
                  setAuthorityTrue();
                }
                setVisibility(false);
              }
            );
          });
        } else {
          return toast("Couldn't find Hive Keychain extension.", {
            type: "error",
            autoClose: 3000
          });
        }
        break;
      }

      default:
        toast("Couldn't find proxy, please sign-in again.", {
          type: "error",
          autoClose: 3000
        });
        break;
    }
  };

  if (!visibility) return null;
  return (
    <FloatingHTMLOverlay
      onClick={ev => ev.stopPropagation()}
      className="bg-overlay z-[1000] flex justify-center py-[10vh]"
      lockScroll
    >
      <div className="relative py-6 px-6 flex flex-col gap-y-6 rounded-xl bg-pri dark:bg-pri-d border border-pri dark:border-pri-d w-10/12 h-fit pc:w-4/12 pc:max-h-fit md:w-7/12 sm:w-9/12 xs:w-full">
        <header className="flex items-center justify-between pb-5">
          <div className="flex flex-1 flex-col pr-10 gap-2">
            <strong className="text-lg">Grant Authority</strong>
          </div>
          <div
            onClick={() => setVisibility(false)}
            className="absolute p-2 h-8 w-8 right-4 flex items-center justify-center rounded-full hover:bg-neutral-200 dark:hover:bg-neutral-700 transition-colors cursor-pointer"
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </header>

        <div className="flex flex-col gap-10">
          In order to use this feature, you need to grant @{authority || "leofinance"} posting authority
        </div>

        <footer className="flex justify-end gap-x-2">
          <button
            className="py-2.5 px-5 rounded-lg border border-pri dark:border-pri-d text-sm font-medium hover:bg-pri-d/[0.075] dark:hover:bg-pri/[0.075] transition-colors duration-150"
            onClick={() => setVisibility(false)}
          >
            Cancel
          </button>
          <button
            className="py-2 px-8 rounded-lg bg-acc text-sm text-pri font-semibold hover:bg-acc transition-colors"
            onClick={() => handleGrant(false)}
          >
            Grant Authority
          </button>
        </footer>
      </div>
    </FloatingHTMLOverlay>
  );
}

