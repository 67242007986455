export function convertYoutubeLinkToEmbed(youtubeLink: string): string {
  const regex =
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?|live)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = youtubeLink.match(regex);
  const videoID = match?.[1];
  if (youtubeLink.includes("shorts")) {
    return `https://www.youtube.com/embed/${videoID}`;
  }
  return `https://www.youtube.com/embed/${videoID}`;
}

