import { useScotContent, useSimpleAnalytics } from "~/hooks/contentHooks";
import type { PossibleHiveContent } from "~/utils/hive";
import { ClientOnly } from "remix-utils/client-only";
import { Fragment, lazy, Suspense, useEffect, useMemo, useState } from "react";
import { useAppStore } from "~/store";
import { BookmarkIcon } from "../bookmarks/BookmarkIcon";
import LikeCount, { DownVoteSlider, LikeSlider, useLiked, useLikeToggleState } from "../format/LikeCount";
import { cache } from "~/utils/cache";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartSimple, faXmark } from "@fortawesome/free-solid-svg-icons";
import Payout from "../Payout";
import { FloatingPortal } from "@floating-ui/react";
import { postThreadMain, editThread as editThreadOperation } from "~/utils/transactions";
import { toast } from "react-toastify";
import EditThread from "./EditThread";
import type { Thread } from "~/utils/leocache";
import { prepareFakeThread } from "~/utils/thread";

const MarkdownEditor = lazy(() => import("~/components/MarkdownEditor"));

export function ThreadActions({
  content,
  fake,
  downvote,
  setDownvote,
  rethread,
  setRethread,
  editThread,
  setEditThread
}: {
  content: Thread;
  fake: boolean;
  downvote: boolean;
  setDownvote: React.Dispatch<React.SetStateAction<boolean>>;
  rethread: boolean;
  setRethread: React.Dispatch<React.SetStateAction<boolean>>;
  editThread: boolean;
  setEditThread: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const activeAccount = useAppStore(store => store.account.activeAccount);
  const isDarkMode = useAppStore(store => store.settings.dark);
  const pushToStart = useAppStore(store => store.threads.pushStartArray);

  const [author, permlink, active_votes] = [content?.author, content?.permlink, content?.active_votes];

  const [state, scotContent, setScotContent] = useScotContent(content);

  const [shortDetails, setShortDetails] = useState({});

  const [likeToggle, setLikeToggle] = useLikeToggleState();

  const isShortsVideo = content?.body?.includes("https://3speak.tv/watch?v=leoshorts");

  let regex, match;

  if (isShortsVideo) {
    try {
      regex = /https:\/\/3speak\.tv\/watch\?v=([^\/]+)\/([^\/]+)/;
      match = (content?.body || "")?.match(regex) || ["", "", ""];
    } catch {
      match = ["", "", ""];
    }
  } else {
    match = ["", "", ""];
  }

  const [, shortsAuthor, shortsPermlink] = match;

  const liked = useLiked(activeAccount?.name ?? null, isShortsVideo ? shortDetails?.scotContent : (scotContent as any));

  const isActive = !!activeAccount;

  useEffect(() => {
    void (async function () {
      if (shortsAuthor === "" || shortsPermlink === "") return;
      const scotContent = await cache.getScotContent({
        author: shortsAuthor,
        permlink: shortsPermlink?.split(" ")[0].replaceAll(" ", "")?.trim()
      });
      const hiveContent = await cache.getContent({
        author: shortsAuthor,
        permlink: shortsPermlink?.split(" ")[0].replaceAll(" ", "")?.trim()
      });

      setShortDetails({
        scotContent,
        hiveContent
      });
    })();
  }, [shortsAuthor, shortsPermlink, isShortsVideo]);

  const handlePost = async (body, pollOptions, dimensions) => {
    await postThreadMain(activeAccount!.name, body, pollOptions, dimensions)
      .then(operation => {
        setRethread(false);
        pushToStart(prepareFakeThread({ operation, activeAccount }));
        toast(`Your thread re-thread is successfully published.`, {
          type: "success",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 3000
        });
      })
      .catch(err => {
        toast(`Failed to publish your re-thread.`, {
          type: "error",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 3000
        });
        throw new Error(err);
      });
  };

  const handleEdit = newContent => {
    editThreadOperation(content, newContent, activeAccount)
      .then(() => {
        setEditThread(false);
        toast(`Your thread ${content.permlink} is successfully updated.`, {
          type: "success",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 3000
        });
      })
      .catch(err => {
        console.log(err);
        toast(`Failed to update thread ${content.permlink}.`, {
          type: "error",
          theme: isDarkMode ? "dark" : "light",
          autoClose: 3000
        });
      });
  };

  const paidOut = useMemo(() => new Date(content?.cashout_time) < new Date(), [content?.permlink]);

  // create a component for analytics and move it
  const { pageViews } = useSimpleAnalytics(`/threads/view/${author}/${permlink}`);

  return (
    <ClientOnly>
      {() => (
        <Fragment>
          <LikeCount
            likeCount={
              isShortsVideo
                ? shortDetails?.scotContent?.active_votes?.length
                : content?.stats?.total_votes! || active_votes?.length || scotContent?.active_votes?.length || 0
            }
            scotContent={isShortsVideo ? shortDetails?.scotContent : scotContent}
            liked={liked}
            setLikeToggle={isActive ? setLikeToggle : undefined}
            paidOut={paidOut}
            className={paidOut && "opacity-25 select-none pointer-events-none"}
          />

          <div
            id={`analytics-buybacks-${author}-${permlink}`}
            className="group/buybacks flex py-0.5 rounded-full text-gray-500 dark:text-zinc-500 hover:text-acc dark:hover:text-acc transition-colors cursor-pointer"
          >
            <div className="group/buybacks flex items-center text-gray-500 dark:text-zinc-500 group-hover/buybacks:text-acc dark:group-hover/buybacks:text-acc transition-colors cursor-pointer">
              <div className="flex justify-center items-center w-9 h-9 sm:w-8 sm:h-8 rounded-full group-hover/buybacks:bg-acc/[.15] group-hover/buybacks:text-acc">
                <FontAwesomeIcon icon={faChartSimple} className="w-4 h-4 sm:w-5 sm:h-5" fixedWidth />
              </div>
              <span className="pl-0.5">{pageViews || 0}</span>
            </div>
          </div>

          <BookmarkIcon author={content?.author} permlink={content?.permlink} />

          {!fake ? (
            <Payout
              scotContent={isShortsVideo ? shortDetails?.scotContent : scotContent}
              hiveContent={isShortsVideo ? shortDetails?.hiveContent : content}
            />
          ) : (
            <div />
          )}

          {isActive ? (
            <LikeSlider
              discussion={content}
              likeToggle={likeToggle}
              setLikeToggle={setLikeToggle}
              setScotContent={setScotContent}
              accountName={activeAccount?.name}
              authorPerm={content}
              scotContent={isShortsVideo ? shortDetails?.scotContent : scotContent}
              isShorts={isShortsVideo}
            />
          ) : null}

          <DownVoteSlider
            discussion={content}
            likeToggle={downvote}
            setLikeToggle={setDownvote}
            setScotContent={setScotContent}
            accountName={activeAccount?.name}
            authorPerm={content}
            scotContent={isShortsVideo ? shortDetails?.scotContent : scotContent}
            isShorts={isShortsVideo}
          />

          <FloatingPortal>
            {rethread && (
              <div
                className="fixed inset-0 py-5 px-3 w-full h-full justify-center items-center bg-black/30 z-[1000] overflow-y-auto overflow-x-hidden"
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <div className="flex flex-col min-w-[340px] max-w-[calc(100vw-12px)] sm:min-w-[540px] sm:max-w-[640px] mx-auto py-5 px-6 rounded-xl drop-shadow-lg bg-pri dark:bg-pri-d border border-pri dark:border-pri-d shadow-[0_0_12px_3px_rgb(255_255_255_/_15%)]">
                  <header className="flex items-center justify-between w-full border-b border-pri dark:border-pri-d pb-4">
                    <span className="font-bold text-xl">Re-thread</span>
                    <div
                      onClick={() => setRethread(false)}
                      className="h-10 w-10 flex items-center justify-center rounded-full border border-pri dark:border-pri-d hover:bg-pri-d/[.075] dark:hover:bg-pri/[.075] transition-colors duration-150 cursor-pointer"
                    >
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                  </header>

                  <div className="flex flex-1 flex-col pt-4">
                    <Suspense fallback={<div></div>}>
                      <MarkdownEditor
                        type={1}
                        initialContent={`https://inleo.io/threads/view/${content?.author}/${content?.permlink}`}
                        handlePost={handlePost}
                        className="border-b-0 px-0"
                      />
                    </Suspense>
                  </div>
                </div>
              </div>
            )}
          </FloatingPortal>

          {editThread && <EditThread setOpen={setEditThread} content={content} handleEdit={handleEdit} type="thread" />}
        </Fragment>
      )}
    </ClientOnly>
  );
}

