interface YoutubeEmbed {
  embedURI: string;
}

export const YoutubeEmbed = ({ embedURI }: YoutubeEmbed) => {
  return (
    <span className="flex relative w-full h-0 pb-[56.25%] rounded-xl overflow-hidden">
      <iframe
        loading="lazy"
        width="100%"
        height="560"
        src={embedURI}
        frameBorder="0"
        className="absolute w-full max-w-full h-full overflow-hidden flex justify-center"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </span>
  );
};

