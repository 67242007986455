import classNames from "classnames";
import Images from "./Images";
import { isThreadLink } from "~/utils/markdown";
import { ClientOnly } from "remix-utils/client-only";
import { useAppStore } from "~/store";
import { useState } from "react";
import { cn } from "~/utils/cn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpLong } from "@fortawesome/free-solid-svg-icons";
import { YoutubeEmbed } from "./YoutubeEmbed";
import { convertYoutubeLinkToEmbed } from "~/utils/single/convertYoutubeLinkEmbed";

interface AnchorProps {
  href: string;
  title: string;
  children: string[];
}

function isImageLink(value: string): boolean {
  if (!value) return false;

  if (value.endsWith(".png")) {
    return true;
  }
  if (value.endsWith(".jpg")) {
    return true;
  }
  if (value.endsWith(".jpeg")) {
    return true;
  }
  if (value.endsWith(".gif")) {
    return true;
  }
  if (value.endsWith(".webp")) {
    return true;
  }
  return false;
}

export default function Anchor({ href, title, children }: AnchorProps) {
  const placeholderVideo = useAppStore(store => store.markdown.placeholderVideo);

  const [iframeLoaded, setIframeLoaded] = useState(false);

  if (isImageLink(href)) {
    return <Images alt={title ?? children} src={href} />;
  }

  if (href?.startsWith("https://3speak.tv/watch?v=") || href?.startsWith("https://3speak.tv/embed?v=")) {
    const [author, permlink] = href
      .replaceAll("https://3speak.tv/watch?v=", "")
      .replaceAll("https://3speak.tv/embed?v=", "")

      .split("/");

    return placeholderVideo?.link === href ? (
      <video className="w-full h-full object-cover" controls>
        <source src={placeholderVideo?.preview} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    ) : (
      <ClientOnly>
        {() => (
          <span className="relative block my-1.5">
            {!iframeLoaded ? (
              <span className="absolute w-full aspect-video bg-gray-200 dark:bg-zinc-700 rounded-xl animate-pulse" />
            ) : null}
            <iframe
              title={title}
              width="100%"
              className={cn("aspect-video rounded-xl opacity-0 transition-opacity duration-150", {
                "opacity-100": iframeLoaded
              })}
              onLoad={() => setIframeLoaded(true)}
              src={`https://3speak.tv/embed?v=${author}/${permlink}`}
              frameBorder="0"
              allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />

            <a
              href={href}
              title={title}
              target="_blank"
              rel="noopener noreferrer"
              className="absolute top-2 right-2 bg-black/50 text-white drop-shadow-sm rounded-full py-1.5 px-3 flex items-center gap-x-2 text-sm no-underline cursor-pointer hover:bg-black/70 transition-colors duration-150"
              onClick={ev => ev.stopPropagation()}
            >
              <img
                src={require("~/images/3speak.png")}
                className="!m-0 !p-0 h-5 object-contain"
                style={{ margin: 0, padding: 0 }}
                alt="3speak"
                height={20}
              />
              <span>View on 3Speak</span>
              <FontAwesomeIcon icon={faArrowUpLong} size="sm" className="rotate-45" />
            </a>
          </span>
        )}
      </ClientOnly>
    );
  }

  const isYoutubeURL = href?.includes("https://youtu") || href?.includes("https://www.youtu");

  const decide = typeof children?.[0] === "string" ? children?.[0] : "";
  const threeSpeak = decide?.startsWith("https://3speak.tv/watch?v=leoshorts");

  if (typeof children?.[0].props?.permlink === "string") return children;

  if (isYoutubeURL) {
    return <YoutubeEmbed embedURI={convertYoutubeLinkToEmbed(href)} />;
  }

  if (threeSpeak) return null;
  return (
    <a
      href={href}
      title={title}
      target="_blank"
      rel="noopener noreferrer"
      className={classNames("[display:_initial] no-underline !font-sans !text-acc leading-none break-words", {
        "hover:underline": !isThreadLink(href),
        "w-full": isThreadLink(href),
        flex: isYoutubeURL
      })}
      style={{
        wordBreak: "normal",
        whiteSpace: "normal"
      }}
      onClick={ev => ev.stopPropagation()}
    >
      {threeSpeak ? <p>View on 3Speak</p> : children}
    </a>
  );
}
